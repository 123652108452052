import { ElevationDegree } from "@fonoa/ui-components/types";

export const getElevationClass = (elevation: ElevationDegree) => {
  return "shadow" + ["-none", "-sm", "", "-md", "-lg", "-xl", "-2xl"][elevation];
};

export const priceFormatter = (value: number | string, currencySign = "$") =>
  `${currencySign} ${parseFloat(String(value)).toFixed(2)}`;

// 0.12    -> 12%
// 0.123   -> 12.3%
// 0.12301 -> 12.3%
// 0.12305 -> 12.31%
export const percentFormatter = (decimal: number, roundToWhole = false) =>
  `${parseFloat(String((decimal * 100).toFixed(roundToWhole ? 0 : 2)))}%`;

export const getInvoiceFileName = (invoiceNumber?: string, date?: string) => {
  // If both invoice number and date present - use them,
  // otherwise - 'Invoice' with number or date if any available
  const formattedDate = date && new Date(date).toLocaleDateString();
  const fileName =
    invoiceNumber && formattedDate
      ? `${invoiceNumber}_${formattedDate}`
      : `Invoice ${invoiceNumber || formattedDate || ""}`.trim();

  return fileName;
};
