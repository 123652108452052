import classNames from "classnames";
import Image from "next/image";
import { FormattedMessage } from "react-intl";

import { useImages } from "@/components/Utils/Images";

export function PieChartPanelError({
  className,
  isApiError,
}: {
  className?: string;
  isApiError: boolean;
}) {
  const Images = useImages();

  return (
    <div
      className={classNames("mx-auto flex grow flex-col items-center justify-center", className)}
    >
      <Image
        src={isApiError ? Images.errorTableResults.src : Images.emptyPieChart.src}
        alt={isApiError ? Images.errorTableResults.alt : Images.emptyPieChart.alt}
        width={isApiError ? Images.errorTableResults.width : Images.emptyPieChart.width}
        height={isApiError ? Images.errorTableResults.height : Images.emptyPieChart.height}
        className="h-auto max-w-full"
      />
      <div className="mt-2.5 w-80 text-center text-sm font-normal text-blueGray600">
        {isApiError ? (
          <FormattedMessage
            defaultMessage="Couldn't retrieve data for this query. We have been notified of the issue and will be addressing it shortly. "
            id="KKNJkN"
          />
        ) : (
          <FormattedMessage defaultMessage="No data available" id="wOFJFO" />
        )}
      </div>
    </div>
  );
}
