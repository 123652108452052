import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { TransactionWarningsFilter } from "@/api/returns/transactions/warnings-summary";
import { useFeatureFlags } from "@/hooks/featureFlags/FeatureFlags";
import api from "@/lib/returns/api";
import { localStorageKeys } from "@/lib/utils";
import { trpc } from "@/utils/trpc";

export function useTransactionWarningsQuery(
  filters: TransactionWarningsFilter = {},
  options?: {
    enabled?: boolean;
  }
) {
  return useQuery(
    ["returnsTransactionWarnings", filters],
    () => api.getTransactionWarningsSummary(filters),
    {
      staleTime: 0,
      enabled: options?.enabled,
    }
  );
}

export function useTransactionWarnings({ country_codes }: { country_codes?: string[] }) {
  const { returnsDashboardTransactionWarningsEnabled: enabled } = useFeatureFlags();

  useEffect(() => {
    if (!localStorage.getItem(localStorageKeys.device_id)) {
      localStorage.setItem(localStorageKeys.device_id, uuidv4());
    }
  }, []);

  const { data, isError, isLoading } = useTransactionWarningsQuery(
    {
      jurisdiction: country_codes?.join(",").toUpperCase(),
    },
    { enabled }
  );

  return {
    enabled,
    data,
    isError,
    isLoading,
  };
}

export function useInputOutputVatData() {
  const { returnsDashboardInputOutputVatBackendReady, returnsDashboardInputOutputVatMockData } =
    useFeatureFlags();

  const { data, isError, isLoading } = trpc.returns.taxSummary.useQuery(undefined, {
    enabled: returnsDashboardInputOutputVatBackendReady === true,
  });

  return {
    data:
      returnsDashboardInputOutputVatBackendReady === true
        ? data
        : returnsDashboardInputOutputVatMockData,
    isError: returnsDashboardInputOutputVatBackendReady === true ? isError : false,
    isLoading: returnsDashboardInputOutputVatBackendReady === true ? isLoading : false,
  };
}

export function useReturnSummaryData() {
  const { returnsDashboardSummaryBackendReady, returnsDashboardSummaryMockData } =
    useFeatureFlags();

  const { data, isError, isLoading } = trpc.returns.summary.useQuery(undefined, {
    enabled: returnsDashboardSummaryBackendReady === true,
  });

  return {
    data: returnsDashboardSummaryBackendReady === true ? data : returnsDashboardSummaryMockData,
    isError: returnsDashboardSummaryBackendReady === true ? isError : false,
    isLoading: returnsDashboardSummaryBackendReady === true ? isLoading : false,
  };
}
