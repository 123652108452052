import { Button, ButtonProps } from "@fonoa/ui-components/button";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CloseIcon,
  StatusErrorIcon,
  StatusInfoIcon,
  StatusSuccessIcon,
  StatusWarningIcon,
} from "@fonoa/ui-components/icons";
import {
  FontSize,
  ThemeColorBg,
  ThemeColorBorder,
  ThemeColorText,
  ThemePadding,
} from "@fonoa/ui-components/types";
import { Typography } from "@fonoa/ui-components/typography";
import classNames from "classnames";
import { ReactNode, useState } from "react";

type BannerAction = {
  title: ReactNode;
  onClick: () => void;
};

export type NotificationBannerProps = {
  isInitiallyOpen?: boolean;
  size?: "xs" | "sm" | "md" | "lg";
  type?: "neutral" | "info" | "success" | "warning" | "error" | "highlight";
  hideLeftIcon?: boolean;
  collapsable?: boolean;
  title: ReactNode;
  description: ReactNode;
  primaryAction?: BannerAction;
  secondaryAction?: BannerAction;
  hideCloseButton?: boolean;
};

const iconFromType: Record<NonNullable<NotificationBannerProps["type"]>, ReactNode> = {
  success: <StatusSuccessIcon size={24} />,
  neutral: <StatusInfoIcon size={24} />,
  info: <StatusInfoIcon size={24} />,
  highlight: <StatusInfoIcon size={24} secondColor="#C8D3DB" />,
  error: <StatusErrorIcon size={24} />,
  warning: <StatusWarningIcon size={24} className="text-tangerine700" />,
};

export const colorMap: Record<
  NonNullable<NotificationBannerProps["type"]>,
  {
    background: ThemeColorBg;
    textColor: ThemeColorText;
    border: ThemeColorBorder;
    textAccent: ThemeColorText;
    primaryButtonVariant?: ButtonProps["variant"];
  }
> = {
  success: {
    textColor: "text-blueGray700",
    background: "bg-green10",
    border: "border-green700",
    textAccent: "text-green700",
  },
  neutral: {
    textColor: "text-blueGray700",
    background: "bg-blueGray25",
    border: "border-blueGray700",
    textAccent: "text-blueGray700",
  },
  info: {
    textColor: "text-blueGray700",
    background: "bg-primaryBlue10",
    border: "border-primaryBlue600",
    textAccent: "text-primaryBlue600",
  },
  error: {
    textColor: "text-blueGray700",
    background: "bg-red10",
    border: "border-red700",
    textAccent: "text-red700",
  },
  warning: {
    textColor: "text-blueGray700",
    background: "bg-tangerine10",
    border: "border-tangerine700",
    textAccent: "text-tangerine700",
  },
  highlight: {
    textColor: "text-white",
    background: "bg-blueGray800",
    border: "border-blueGray800",
    textAccent: "text-white",
    primaryButtonVariant: "OUTLINED",
  },
};

export const sizeMap: Record<
  NonNullable<NotificationBannerProps["size"]>,
  { padding: ThemePadding; fontSize: FontSize; button: ButtonProps["size"] }
> = {
  xs: { padding: "p-1", fontSize: "text-xs", button: "SMALL" },
  sm: { padding: "p-2", fontSize: "text-xs", button: "SMALL" },
  md: { padding: "p-4", fontSize: "text-sm", button: "REGULAR" },
  lg: { padding: "p-6", fontSize: "text-base", button: "REGULAR" },
};

const TopRightIcon = ({
  collapsable,
  collapsed,
  color,
}: {
  collapsable?: boolean;
  collapsed?: boolean;
  color: ThemeColorText;
}) => {
  if (!collapsable) {
    return <CloseIcon size={14} className={color} />;
  }
  return collapsed ? (
    <ArrowUpIcon size={12} className={color} />
  ) : (
    <ArrowDownIcon size={12} className={color} />
  );
};

export const NotificationBanner = ({
  isInitiallyOpen = true,
  size = "md",
  type = "info",
  title,
  description,
  primaryAction,
  secondaryAction,
  collapsable,
  hideLeftIcon = false,
  hideCloseButton = false,
}: NotificationBannerProps) => {
  const colors = colorMap[type];
  const sizes = sizeMap[size];

  const [closed, setClosed] = useState(!isInitiallyOpen);

  const toggleClosed = () => setClosed(!closed);

  if (!collapsable && closed) {
    return null;
  }

  return (
    <div
      className={classNames(
        "items-top flex rounded-md border",
        colors.background,
        colors.border,
        sizes.padding
      )}
    >
      {!hideLeftIcon && <div className="leading-7">{iconFromType[type]}</div>}
      <div
        className={classNames("w-full pl-3", {
          "pb-2": !closed,
        })}
      >
        <div className="flex justify-between">
          <Typography
            component="h3"
            color={colors.textColor}
            fontWeight="font-medium"
            fontSize={sizes.fontSize}
          >
            {title}
          </Typography>
          {!hideCloseButton && (
            <button
              className="-mt-1 mr-1 cursor-pointer"
              onClick={toggleClosed}
              data-testid="banner-close-icon"
            >
              <TopRightIcon collapsable={collapsable} collapsed={closed} color={colors.textColor} />
            </button>
          )}
        </div>
        {!closed && (
          <Typography component="p" color={colors.textColor} fontSize={sizes.fontSize}>
            {description}
          </Typography>
        )}

        {Boolean(!closed && (primaryAction || secondaryAction)) && (
          <div className="mt-6 flex w-full text-left">
            {primaryAction && (
              <Button
                variant={colors.primaryButtonVariant || "FILLED"}
                spacing={{ mr: 4 }}
                UNSAFE_className="font-bold"
                onClick={primaryAction.onClick}
                size={sizes.button}
              >
                {primaryAction.title}
              </Button>
            )}

            {Boolean(secondaryAction) && (
              <Button
                variant={type === "highlight" ? "TEXT_LIGHT" : "TEXT"}
                onClick={secondaryAction?.onClick}
                size={sizes.button}
                bold={Boolean(!primaryAction)}
                UNSAFE_className={classNames({
                  [colors.textAccent]: Boolean(!primaryAction),
                })}
              >
                {secondaryAction?.title}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
