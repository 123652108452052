import { Typography } from "@fonoa/ui-components/typography";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

import { StaticNotificationBanner } from "@/components/NotificationBanner/StaticNotificationBanner";
import { noop } from "@/lib/utils";

type SummaryOverdueProps = {
  className?: string;
  data?: {
    in_progress?: number;
    not_started?: number;
  };
  loading?: boolean;
  error?: boolean;
  onViewActionClick?: () => void;
};

type Keys = keyof NonNullable<SummaryOverdueProps["data"]>;

export const SummaryOverdue = ({ className, data, onViewActionClick }: SummaryOverdueProps) => {
  const intl = useIntl();
  const names: Record<Keys, string> = {
    in_progress: intl.formatMessage({ defaultMessage: "in progress", id: "NhDwgx" }),
    not_started: intl.formatMessage({ defaultMessage: "not started", id: "va0t4w" }),
  };

  const total = (data?.in_progress || 0) + (data?.not_started || 0);

  if (!total) {
    return null;
  }

  return (
    <div className={classNames(className)}>
      <StaticNotificationBanner
        size="xs"
        type="warning"
        message={
          <div className="flex space-x-1">
            <Typography fontSize="text-xs" fontWeight="font-medium" color="text-blueGray700">
              {total > 1 ? (
                <FormattedMessage
                  defaultMessage="You have {total} returns filing overdue"
                  values={{ total }}
                  id="AnCaHU"
                />
              ) : (
                <FormattedMessage defaultMessage="You have 1 return filing overdue" id="oKeeuY" />
              )}
            </Typography>
            <Typography fontSize="text-xs" color="text-blueGray700">
              {(["in_progress", "not_started"] as Keys[])
                .filter((k) => data?.[k])
                .map((key: Keys) => `${total > 1 ? data?.[key] : ""} ${names[key]}`)
                .join(", ")}
            </Typography>
          </div>
        }
        action={{
          title: intl.formatMessage({ defaultMessage: "View", id: "FgydNe" }),
          onClick: onViewActionClick || noop,
        }}
      />
    </div>
  );
};
