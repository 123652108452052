import { colors } from "@fonoa/design/tokens";
import { Button } from "@fonoa/ui-components/button";
import {
  StatusErrorIcon,
  StatusInfoIcon,
  StatusSuccessIcon,
  StatusWarningIcon,
} from "@fonoa/ui-components/icons";
import classNames from "classnames";
import { ReactNode } from "react";

import { colorMap, sizeMap } from "./NotificationBanner";

type BannerAction = {
  title: ReactNode;
  onClick: () => void;
  loading?: boolean;
};

export type SimpleNotificationBannerProps = {
  isInitiallyOpen?: boolean;
  size?: "xs" | "sm" | "md" | "lg";
  type?: "neutral" | "info" | "success" | "warning" | "error" | "highlight";
  message: ReactNode;
  action?: BannerAction;
  iconSize?: number;
};

const getIcon = (type: NonNullable<SimpleNotificationBannerProps["type"]>, size: number) => {
  const iconFromType: Record<NonNullable<SimpleNotificationBannerProps["type"]>, ReactNode> = {
    success: <StatusSuccessIcon size={size} />,
    neutral: <StatusInfoIcon size={size} />,
    info: <StatusInfoIcon size={size} />,
    highlight: <StatusInfoIcon size={size} secondColor="#C8D3DB" />,
    error: <StatusErrorIcon size={size} />,
    warning: (
      <StatusWarningIcon
        size={size}
        className="text-tangerine700"
        secondColor={colors.tangerine200}
      />
    ),
  };

  return iconFromType[type];
};

export const StaticNotificationBanner = ({
  size = "md",
  type = "info",
  message,
  iconSize = 20,
  action,
}: SimpleNotificationBannerProps) => {
  const colors = colorMap[type];
  const sizes = sizeMap[size];

  return (
    <div
      className={classNames(
        "flex items-center rounded-md border border-l-4 pl-2 pr-6",
        colors.background,
        colors.border,
        sizes.padding
      )}
    >
      <div className="mb-1 leading-7">{getIcon(type, iconSize)}</div>

      <div className="w-full pl-3">
        <div className="flex justify-between">
          {message}

          {action && (
            <Button
              variant={type === "highlight" ? "TEXT_LIGHT" : "TEXT"}
              onClick={action?.onClick}
              size={sizes.button}
              bold
              loading={action?.loading}
            >
              <span className={colors.textAccent}>{action?.title}</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
