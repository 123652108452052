import { colors } from "@fonoa/design/tokens";
import { Typography } from "@fonoa/ui-components/typography";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import LoadingWrapper from "@/components/Loaders/LoadingWrapper";

import type { SummaryData } from "./SummaryPanel";

type SummaryCountersProps = {
  className?: string;
  data?: SummaryCounterData;
  loading?: boolean;
  error?: boolean;
};

export type SummaryCounterData = {
  total: number;
  rows: SummaryData[];
};

export const SummaryCounters = ({ data, loading, className }: SummaryCountersProps) => {
  return (
    <div className={classNames("flex flex-col justify-center space-y-2", className)}>
      <table>
        <thead>
          <tr className="rounded-sm bg-blueGray25">
            <td className="px-3 py-1">
              <Typography color="text-blueGray600">
                <FormattedMessage defaultMessage="Status" id="tzMNF3" />
              </Typography>
            </td>
            <td className="px-2 text-center font-mono">
              <Typography color="text-blueGray600">%</Typography>
            </td>
            <td className="px-2 text-right">
              <Typography color="text-blueGray600">
                <FormattedMessage defaultMessage="# Returns" id="u5bjVl" />
              </Typography>
            </td>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <>
              <Row loading={true} />
              <Row loading={true} />
              <Row loading={true} />
            </>
          )}

          {!loading && data?.rows.map((row) => <Row key={row.id} row={row} />)}
          <tr>
            <td className="h-1" />
          </tr>
        </tbody>
        <tfoot>
          <tr className="border-t border-blueGray200">
            <td className="px-2 pt-1">
              <LoadingWrapper loading={loading}>
                <Typography>
                  <FormattedMessage defaultMessage="Total" id="MJ2jZQ" />
                </Typography>
              </LoadingWrapper>
            </td>
            <td />
            <td className="px-2 pt-1 text-right font-mono">
              <LoadingWrapper loading={loading} align="right">
                <Typography color="text-primaryBlue500">{data?.total}</Typography>
              </LoadingWrapper>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const Row = ({ row, loading }: { row?: SummaryData; loading?: boolean }) => (
  <tr>
    <td className="px-2 pt-2">
      <LoadingWrapper loading={loading} align="left">
        <Typography>
          <Dot color={row?.color || colors.blueGray200} className="mr-2" />
          {row?.label}
        </Typography>
      </LoadingWrapper>
    </td>
    <td className="px-2 pt-2 text-center font-mono">
      <LoadingWrapper loading={loading} width="w-10" align="center">
        <Typography>{row?.percentage}</Typography>
      </LoadingWrapper>
    </td>
    <td className="px-2 pt-2 text-right font-mono">
      <LoadingWrapper loading={loading} align="right">
        <Typography color="text-primaryBlue500">{row?.value}</Typography>
      </LoadingWrapper>
    </td>
  </tr>
);

const Dot = ({ color, className }: { color: string; className?: string }) => (
  <span
    className={classNames("inline-block h-2 w-2 rounded-full", className)}
    style={{ backgroundColor: color }}
  />
);
