import { colors } from "@fonoa/design/tokens";
import { ThemeColor } from "@fonoa/ui-components/types";
import { Pie, PieSvgProps } from "@nivo/pie";
import classNames from "classnames";

import type { SummaryData } from "./SummaryPanel";

type SummaryPieChartData = Omit<SummaryData, "percentage" | "label">;

type SummaryPieChartProps = {
  className?: string;
  data: SummaryPieChartData[];
  loading?: boolean;
  error?: boolean;
  height: number;
  width: number;
} & Omit<PieSvgProps<SummaryPieChartData>, "height" | "width">;

const loadingData = [
  {
    id: "Loading",
    value: 100,
    color: colors.blueGray200 as ThemeColor,
  },
];

export const SummaryPieChart = ({ data, loading, className, ...props }: SummaryPieChartProps) => {
  const chartData = loading ? loadingData : data;

  return (
    <div
      className={classNames("mx-12 flex", className)}
      aria-hidden="true" /* Hiding for screen readers as nivo charts is currenty not screen reader friendly */
    >
      <Pie
        data={chartData}
        innerRadius={0.5}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={(d) => d.data.color || colors.blueGray50}
        {...props}
      />
    </div>
  );
};
