import { colors } from "@fonoa/design/tokens";
import { components } from "@fonoa/returns/types";
import { ThemeColor } from "@fonoa/ui-components/types";
import { Typography } from "@fonoa/ui-components/typography";
import { ReactNode, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { percentFormatter } from "@/components/Utils";
import { PieChartPanelError } from "@/features/ReturnsDashboard/components/PieChartPanelError";

import { SummaryCounters } from "./SummaryCounters";
import { SummaryOverdue } from "./SummaryOverdue";
import { SummaryPieChart } from "./SummaryPieChart";

type SummaryPanelProps = {
  data?: components["schemas"]["ReturnsSummaryResponse"]["data"];
  error?: boolean;
  loading?: boolean;
  onOverdueViewClick?: () => void;
};

export const SummaryPanel = ({ data, error, loading, onOverdueViewClick }: SummaryPanelProps) => {
  const summaryData = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return {
      total: data.total,
      rows: [
        {
          id: "completed",
          label: <FormattedMessage defaultMessage="Completed" id="95stPq" />,
          value: data.completed,
          percentage: percentFormatter(data.completed / data.total, true),
          color: colors.green300 as ThemeColor,
        },
        {
          id: "in_progress",
          label: <FormattedMessage defaultMessage="In progress" id="q1WWIr" />,
          value: data.in_progress,
          percentage: percentFormatter(data.in_progress / data.total, true),
          color: colors.yellow600 as ThemeColor,
        },
        {
          id: "not_started",
          label: <FormattedMessage defaultMessage="Not started" id="xTTP0x" />,
          value: data.not_started,
          percentage: percentFormatter(data.not_started / data.total, true),
          color: colors.blueGray50 as ThemeColor,
        },
      ],
    };
  }, [data]);

  const hasNoContent = error || (!loading && !data?.total);
  const hasContent = !hasNoContent;

  return (
    <div className="mt-4 rounded-sm border border-blueGray200 p-6">
      <div className="flex w-full flex-row justify-between">
        <Typography variant="h5" fontWeight="font-medium" color="text-blueGray900">
          <FormattedMessage defaultMessage="Returns summary" id="X4t2uW" />
        </Typography>
        {hasContent && (
          <Typography variant="span" color="text-blueGray700">
            <FormattedMessage defaultMessage="Returns due this month" id="joU3fG" />
          </Typography>
        )}
      </div>
      {hasNoContent ? (
        <PieChartPanelError className="my-8" isApiError={Boolean(error)} />
      ) : (
        <SummaryContent
          data={summaryData}
          overdueData={{
            in_progress: data?.overdue_in_progress,
            not_started: data?.overdue_not_started,
          }}
          loading={loading}
          error={error}
          onOverdueViewClick={onOverdueViewClick}
        />
      )}
    </div>
  );
};

type SummaryContentProps = {
  data?: {
    total: number;
    rows: SummaryData[];
  };
  overdueData?: {
    in_progress?: number;
    not_started?: number;
  };
  loading?: boolean;
  error?: boolean;
  onOverdueViewClick?: () => void;
};

export type SummaryData = {
  id: string;
  label: ReactNode;
  value: number;
  percentage: string;
  color: ThemeColor;
};

const SummaryContent = ({
  data,
  overdueData,
  loading,
  error,
  onOverdueViewClick,
}: SummaryContentProps) => (
  <>
    <div className="my-8 flex flex-col justify-between space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
      <SummaryPieChart
        className="flex-2"
        height={164}
        width={164}
        data={data?.rows || []}
        loading={loading}
        error={error}
      />
      <SummaryCounters className="flex-1" data={data} error={error} loading={loading} />
    </div>

    {!loading && !error && (
      <SummaryOverdue
        data={overdueData}
        loading={loading}
        error={error}
        onViewActionClick={onOverdueViewClick}
      />
    )}
  </>
);
