import { SpacingProps, ValidTailwindSizes } from "@fonoa/ui-components/types";
import classNames from "classnames";

import LoaderBar from "./LoaderBar";

export interface LoadingWrapperProps extends SpacingProps {
  loading: boolean | undefined;
  animated?: boolean;
  children: React.ReactNode;
  align?: "left" | "center" | "right";
  width?: `w-${ValidTailwindSizes}`;
  height?: `h-${ValidTailwindSizes}`;
}

const alignMap: Record<"left" | "center" | "right", string> = {
  left: "mr-auto",
  center: "m-auto",
  right: "ml-auto",
};

export default function LoadingWrapper({
  loading,
  children,
  width = "w-32",
  height = "h-4",
  align = "left",
  animated = true,
  spacing = { my: 0 },
}: LoadingWrapperProps) {
  if (loading) {
    return (
      <LoaderBar
        className={classNames("rounded-sm", alignMap[align])}
        width={width}
        height={height}
        animated={animated}
        spacing={spacing}
      />
    );
  }

  return <>{children}</>;
}
